.header {
  padding-bottom: 22px;
}

.container {
  max-width: 1280px;
  margin: auto;
  padding: 0 40px;
  overflow: hidden;
}

.mainNav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  gap: 12px;
}

.logo {
  color: #f5f5f5;
  font-size: 35px;
  transition: ease-in-out 0.1s;
  font-family: 'Merriweather', sans-serif;
}

.logo:hover {
  color: #FF9277;
}

ul {
  width: 420px;
  display: flex;
  justify-content: space-between;
  gap: 8px;
}

.link {
color: #fff;
text-transform: uppercase;
border-bottom: 3px solid transparent;
transition: ease-in 0.2s;
padding-bottom: 4px;
}

.link:hover {
  border-color: #FF9277;
}

.currentPage {
  border-bottom: 3px solid #FF9277;
}

@media (max-width: 580px) {
  .mainNav {
    display: flex;
    flex-direction: column;
    text-align: center;
    height: auto;
    gap: 24px;
    margin-top: 12px;
  }

  ul {
    width: 100%;
  }

  li {
    margin-bottom: 14px;
  }
}

@media (max-width: 460px) {
  .header {
    padding-bottom: 0;
  }
}

@media (max-width: 350px) {
  ul {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  li {
    margin-bottom: 4px;
  }

  .link {
    padding-bottom: 1px;
  }
}