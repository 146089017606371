@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap');

.projectsPage {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 60px;
  margin: 0 auto;
  max-width: 1280px;
}

@keyframes cd-fade-from-left {
  0% {
    opacity: 0;
    transform: translateX(-150px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes cd-fade-from-right {
  0% {
    opacity: 0;
    transform: translateX(150px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.projectCard {
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 600px;
  border-radius: 6px 6px 4px 4px;
  background-color: #f5f5f5;
}

.projectCard:nth-child(even) {
  animation: cd-fade-from-left 0.6s ease forwards;
}

.projectCard:nth-child(odd) {
  animation: cd-fade-from-right 0.6s ease forwards;
}

.projectCard h1, 
.projectCard p {
  color: #22333B;
}

.projectInfoContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 24px;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 12px;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  font-family: 'Montserrat', sans-serif;
  width: 150px;
  height: 30px;
  border-radius: 8px;
  color: #CAD2D6;
  background-color: #22333B;
  border: none;
  text-align: center;
  transition: all ease-in-out 0.1s;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.button:hover {
  background-color: #333E3A;
}

.project {
  height: 195px;
  border-radius: 4px 4px 0 0;
  transition: ease-in-out 2s;
  border-bottom: 2px solid #FF9277;
}

.project1 {
  background: url('../images/crypto-tracker.png');
  background-size: cover;
}

.project2 {
  background: url('../images/chat-room.png');
  background-size: cover;
}

.project3 {
  background: url('../images/perssons-maleri.png');
  background-size: cover;
}

.project:hover {
  background-position: bottom;
}

@media (max-width: 680px) {
  .projectCard {
    width: 400px;
  }
}

@media (max-width: 450px) {
  .projectsContainer {
    height: 850px;
  }

  .project {
    height: 150px;
  }

  .projectCard {
    width: 300px;
  }

  .buttonContainer {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 65px;
    gap: 6px;
    margin-top: 6px;
  }

  .button {
    width: 90%;
  }

  .projectInfoContainer {
    gap: 6px;
    padding: 12px 24px;
  }

  .projectInfoContainer > h1 {
    font-size: 24px;
  }
}

@media (max-width: 350px) {
  .projectsPage {
    gap: 50px;
  }
}