/* Headers */
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700&display=swap');
/* Body */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
/* Components */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap');


* {
  margin: 0;
  padding: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: 'Roboto', sans-serif;
  background: #fff;
  color: #f5f5f5;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Merriweather', serif;
  color: #f5f5f5;
}

a {
  text-decoration: none;
  color: #f5f5f5;
}

p {
  color: #f5f5f5;
}

ul {
  list-style: none;
}

/* scrollbar */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #22333b;
  cursor: default;
}

::-webkit-scrollbar-thumb {
  background: #f5f5f5;
  cursor: default;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}