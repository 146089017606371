.contactSection {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  color: #f5f5f5;
  width: 1280px;
  flex-grow: 1;
}

.contactContainer {
  display: flex;
  flex-direction: column;
  gap: 62px;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 4px;
}

.contactInfo {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 12px;
}

.buttonAndHeadingContainer {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.chevronButton {
  z-index: 2;
  display: flex;
  margin: auto 0;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: #22333B;
  cursor: pointer;
  border: 2px solid white;
}

.chevronButton:hover {
  background-color: rgba(34, 51, 59, 0.5);
}

.contactHeading {
  display: flex;
  gap: 10px;
  font-size: 30px;
  align-items: center;
}

.contactHeading h3 {
  cursor: default;
}

.svgIcon {
  fill: #fff;
  height: 30px;
  width: 30px;
}

.chevronIcon {
  color: #FF9277;
  margin: auto;
}

.contactContent {
  background-color: transparent;
  font-size: 30px;
  cursor: text;
}

.invisible {
  color: transparent;
}

.arrowImg {
  width: 200px;
  display: flex;
  align-self: flex-end;
  margin-top: auto;
  margin-right: 15px;
}

@media (max-width: 1360px) {
  .contactSection {
    width: 100vw;
  }

  .contactContainer {
    width: auto;
    margin-left: 40px;
    margin-right: 40px;
  }

  .arrowImg {
    margin-right: 55px;
  }
}

@media (max-width: 550px) {
  .arrowImg {
    align-self: center;
    margin-right: 70px;
  }
}

@media (max-width: 430px) {
  .contactHeading {
    font-size: 20px;
  }

  .svgIcon {
    height: 22px;
    width: 22px;
  }

  .contactContent {
    font-size: 20px;
  }

  .chevronButton {
    height: 20px;
    width: 20px;
  }

  .chevronIcon {
    width: 10px;
  }
}

/* Animation Keyframes */
@keyframes cd-fade-from-left {
  0% {
    opacity: 0;
    transform: translateX(-150px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes cd-fade-from-right {
  0% {
    opacity: 0;
    transform: translateX(150px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Apply animations to contactInfo */
.contactInfo:nth-child(even) {
  animation: cd-fade-from-left 0.6s ease forwards;
}

.contactInfo:nth-child(odd) {
  animation: cd-fade-from-right 0.6s ease forwards;
}
