.footer {
  margin-top: auto;
  padding-top: 34px;
  padding-bottom: 12px;
}

.container {
  max-width: 1280px;
  margin: auto;
  padding: 0 40px;
  overflow: hidden;
}

.footerContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}

.social {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: auto;
  width: 150px;
}

.social a {
  color: #f5f5f5;
  border: 2px solid #f5f5f5;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  line-height: 20px;
  text-align: center;
  padding: 8px;
  transition: ease-in 0.1s;
}

.social a:hover {
  color: #FF9277;
}

.icon {
  height: 20px;
  width: 20px;
}

@media (max-width: 550px) {
  .footer {
    padding: 24px 0;
  }

  .footerContent {
    flex-direction: column;
    justify-content: space-evenly;
    flex-direction: column-reverse;
  }
}

@media (max-width: 340px) {
  .footerContent p {
    font-size: small;
  }
}