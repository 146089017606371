@import url('../variables.css');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700&display=swap');

.homePage {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  gap: 60px;
  color: #fff;
  width: 1280px;
}

.typewriterSection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  margin: auto 40px;
}

.profileContainer {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 300px;
  max-height: 300px;
  border-radius: 50%;
  overflow: hidden;
  z-index: 2;
}

.profileImg {
  width: 100%;
}

.typewriter {
  font-size: 64px;
  line-height: 1.2;
  color: #f5f5f5;
  font-family: 'Merriweather', serif;
}

.developmentSection {
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 4px;
}

.developmentHeading {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.sectionTitle {
  z-index: 1;
  font-size: 32px;
  font-weight: 400;
  color: #22333B;
  margin: 15px;
}

.processContainer {
  height: 220px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  text-align: center;
}

.processContainer h3 {
  z-index: 2;
  color: #22333B;
}

.processChunk {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.procesStep {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 28px;
  background: #FF9277;
  border-radius: 50%;
  height: 15px;
  width: 15px;
  line-height: 15px;
  padding: 18px;
  transition: all 1s;
}

.processIcon {
  align-self: center;
  justify-self: center;
  border-radius: 50%;
  background: #22333B;
  color: #f5f5f5;
  padding: 30px 30px 22px 30px;
  width: 70px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  position: relative;
  transition: all 0.5s;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.processIcon:hover {
  background: #FF9277;
}

.processIcon:hover .procesStep {
  background: #22333B;
}

@media (max-width: 1360px) {
  .homePage {
    width: 100vw;
  }

  .developmentSection {
    width: auto;
    margin-left: 40px;
    margin-right: 40px;
  }
}

@media (max-width: 720px) {
  .typewriter {
    font-size: 45px;
  }

  .typewriterSection {
    margin: auto 12px;
  }
}

@media (max-width: 640px) {
  .processContainer {
    flex-direction: column;
    height: 800px;
  }

  .processChunk {
    gap: 10px;
  }
}

@media (max-width: 610px) {
  .sectionTitle {
    line-height: 1.4;
  }
}

@media (max-width: 470px) {
  .homePage {
    gap: 36px;
  }

  .profileContainer {
    max-width: 250px;
    max-height: 250px;
  }

  .typewriter {
    font-size: 35px;
  }

  .sectionTitle {
    font-size: 24px;
  }
}

@media (max-width: 375px) {
  .profileContainer {
    max-width: 200px;
    max-height: 200px;
  }

  .typewriter {
    font-size: 28px;
  }
}

/* Floating section */
.floatingSection {
  display: flex;
  background-color: transparent;
  margin: 0 auto;
  overflow: hidden;
  border: 2px solid #fff;
  border-radius: 100px;
  margin: 0 40px;
}

.floatingSection>div:first-child {
  border-right: 2px solid #fff;
}

.floatingWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 250px;
}

.floatingHeaderWrapper {
  display: flex;
  margin: 12px;
  gap: 6px;
  align-items: center;
  justify-content: center;
}

.floatingHeaderWrapper>svg {
  width: 30px;
  height: 30px;
}

.floatingHeader {
  text-align: center;
}

.floatinHeaderSvg {
  height: 30px;
  width: 30px;
  fill: #FF9277;
}

/* Flex container styling */
.floatingContainer {
  display: flex;
  flex: 1;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

/* Common floating styles */
.floating {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  color: #fff;
  background: transparent;
  animation: 6s infinite ease-in-out;
}

.floatingIcon {
  width: 50px;
  height: 50px;
  fill: #fff;
}

.floatingSectionWrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.floatingSectionWrapper>h1:first-child {
  font-size: 42px;
  text-align: center;
}

@media (max-width: 900px) {
  .floatingSectionWrapper {
    gap: 18px;
  }

  .floatingSection {
    flex-direction: column;
    height: 300px;
    margin: 0 24px;
    border-radius: 24px;
  }

  .floatingSection>div:first-child {
    border-bottom: 2px solid #fff;
    border-right: none;
  }

  .floatingIcon {
    height: 30px;
    width: 30px;
  }

  .floatingHeader {
    font-size: 18px;
  }

  .floating>p {
    font-size: 12px;
  }

  .floatingSectionWrapper>h1:first-child {
    font-size: 22px;
  }

  .floatingHeaderWrapper>svg {
    width: 20px;
    height: 20px;
  }
}

/* Floating elements */
/* Different animations for each floating div */
.floating1 {
  animation: floating1 8s infinite ease-in-out;
}

.floating2 {
  animation: floating2 10s infinite ease-in-out;
}

.floating3 {
  animation: floating3 12s infinite ease-in-out;
}

.floating4 {
  animation: floating4 9s infinite ease-in-out;
}

.floating5 {
  animation: floating5 11s infinite ease-in-out;
}

.floating6 {
  animation: floating6 13s infinite ease-in-out;
}

.floating7 {
  animation: floating7 7s infinite ease-in-out;
}

.floating8 {
  animation: floating8 14s infinite ease-in-out;
}

/* Keyframes for each animation */
@keyframes floating1 {
  0% {
    transform: translate(0, 0);
  }

  25% {
    transform: translate(20px, 20px);
  }

  50% {
    transform: translate(-20px, -15px);
  }

  75% {
    transform: translate(15px, -20px);
  }

  100% {
    transform: translate(0, 0);
  }
}

@keyframes floating2 {
  0% {
    transform: translate(0, 0);
  }

  25% {
    transform: translate(-20px, 15px);
  }

  50% {
    transform: translate(20px, -20px);
  }

  75% {
    transform: translate(-15px, 20px);
  }

  100% {
    transform: translate(0, 0);
  }
}

@keyframes floating3 {
  0% {
    transform: translate(0, 0);
  }

  25% {
    transform: translate(15px, -20px);
  }

  50% {
    transform: translate(-20px, 20px);
  }

  75% {
    transform: translate(20px, -15px);
  }

  100% {
    transform: translate(0, 0);
  }
}

@keyframes floating4 {
  0% {
    transform: translate(0, 0);
  }

  25% {
    transform: translate(20px, -15px);
  }

  50% {
    transform: translate(-15px, 20px);
  }

  75% {
    transform: translate(-20px, -20px);
  }

  100% {
    transform: translate(0, 0);
  }
}

@keyframes floating5 {
  0% {
    transform: translate(0, 0);
  }

  25% {
    transform: translate(-15px, 20px);
  }

  50% {
    transform: translate(20px, -20px);
  }

  75% {
    transform: translate(20px, 15px);
  }

  100% {
    transform: translate(0, 0);
  }
}

@keyframes floating6 {
  0% {
    transform: translate(0, 0);
  }

  25% {
    transform: translate(20px, -20px);
  }

  50% {
    transform: translate(15px, 20px);
  }

  75% {
    transform: translate(-20px, 15px);
  }

  100% {
    transform: translate(0, 0);
  }
}

@keyframes floating7 {
  0% {
    transform: translate(0, 0);
  }

  25% {
    transform: translate(20px, 15px);
  }

  50% {
    transform: translate(-15px, -20px);
  }

  75% {
    transform: translate(-20px, 20px);
  }

  100% {
    transform: translate(0, 0);
  }
}

@keyframes floating8 {
  0% {
    transform: translate(0, 0);
  }

  25% {
    transform: translate(-20px, -15px);
  }

  50% {
    transform: translate(20px, 20px);
  }

  75% {
    transform: translate(15px, -20px);
  }

  100% {
    transform: translate(0, 0);
  }
}

/* Adjusted keyframes for smaller screen sizes */
@media (max-width: 900px) {
  @keyframes floating1 {
    0% {
      transform: translate(0, 0);
    }

    25% {
      transform: translate(10px, 10px);
    }

    50% {
      transform: translate(-10px, -7px);
    }

    75% {
      transform: translate(7px, -10px);
    }

    100% {
      transform: translate(0, 0);
    }
  }

  @keyframes floating2 {
    0% {
      transform: translate(0, 0);
    }

    25% {
      transform: translate(-10px, 7px);
    }

    50% {
      transform: translate(10px, -10px);
    }

    75% {
      transform: translate(-7px, 10px);
    }

    100% {
      transform: translate(0, 0);
    }
  }

  @keyframes floating3 {
    0% {
      transform: translate(0, 0);
    }

    25% {
      transform: translate(7px, -10px);
    }

    50% {
      transform: translate(-10px, 10px);
    }

    75% {
      transform: translate(10px, -7px);
    }

    100% {
      transform: translate(0, 0);
    }
  }

  @keyframes floating4 {
    0% {
      transform: translate(0, 0);
    }

    25% {
      transform: translate(10px, -7px);
    }

    50% {
      transform: translate(-7px, 10px);
    }

    75% {
      transform: translate(-10px, -10px);
    }

    100% {
      transform: translate(0, 0);
    }
  }

  @keyframes floating5 {
    0% {
      transform: translate(0, 0);
    }

    25% {
      transform: translate(-7px, 10px);
    }

    50% {
      transform: translate(10px, -10px);
    }

    75% {
      transform: translate(10px, 7px);
    }

    100% {
      transform: translate(0, 0);
    }
  }
}